import type { UseFetchOptions } from "nuxt/app";
import { Method } from "~/enums";
import { useUserStore } from "~/store/userStore";
import { defu } from "defu";

export async function useCustomFetch<T>(url: string | (() => string), options: UseFetchOptions<T> = {}) {
	// const config = useRuntimeConfig();
	const userStore = useUserStore();
	const userToken = useCookie("userToken");
	// const router = useRouter();
	// const userToken = useCookie("userToken");
	// const defaults: UseFetchOptions<T> = {
	// 	baseURL: config.public.authOrigin,
	// 	// cache request
	// 	key: url as string,
	// 	method: options.method ?? "GET",
	// 	query: options.query,
	// 	params: options.params,
	// 	body: options.body,

	// 	// set user token if connected
	// 	headers: userStore.apiTokenInfo ? { Authorization: `Bearer ${userStore.apiTokenInfo.accessToken}` } : {},
	// 	// headers: userToken.value ? { Authorization: `Bearer ${userToken.value}` } : {},

	// 	async onRequest(_ctx) {
	// 		etcStore.setLoading();
	// 		if (!userToken.value) {
	// 			const result = await userStore.refreshToken();
	// 			_ctx.options.headers = { Authorization: `Bearer ${userStore.apiTokenInfo.accessToken}` };
	// 		}
	// 	},

	// 	onResponse(_ctx) {},
	// };

	// const params = defu(options, defaults);
  // console.log(url);
  // userStore.accessTokenClear();

  let accessToken = userToken ?? '';

  if(!accessToken || accessToken.value === '' ){
    alert('다시 로그인 해주세요.');
    return;
  }

	let result = await useFetch(url, {
		method: options.method ?? "GET",
		headers: { Authorization: `Bearer ${accessToken.value}` },
		query: options.query,
    params: options.params,
    body: options.body,

	});

  return result;
}
