export default defineNuxtRouteMiddleware((to, from) => {
  console.log(process.env.NODE_ENV);
	// isAuthenticated() is an example method verifying if a user is authenticated
	if (process.env.NODE_ENV === "production") {
		if (to.fullPath.includes("swagger-ui")) {
      console.log('match swagger');
			return abortNavigation();
		}
	}
});
