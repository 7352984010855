import {defineRule} from 'vee-validate';

export default defineNuxtPlugin(() => {
  defineRule('email', value => {
    // Field is empty, should pass
    if (!value || !value.length) {
      return true;
    }
    // Check if email
    if (!/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/.test(value)) {
      return '이메일 형식에 맞지 않습니다.';
    }

    return true;
  });

  defineRule('companyEmail', value => {
    // Field is empty, should pass
    if (!value || !value.length) {
      return true;
    }
    // Check if email
    if (!/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/.test(value)) {
      return '이메일 형식에 맞지 않습니다.';
    }

    if(!value.includes('kgct.or.kr')){
      return '이메일은 회사 내부 이메일 주소를 사용해 주세요.';
    }
    return true;
  });
  
  defineRule(`minLength`, (value, [limit]) => {
    if (!value || !value.length) {
      return false;
    }
    // Check if email
    if (value.length <= limit) {
      return `${limit}자 이상으로 작성해주세요.`;
    }
    return true;
  
  })
  
  defineRule('maxLength', (value, [limit]) => {
    if (!value || !value.length) {
      return false;
    }
    // Check if email
    if (value.length >= limit) {
      return `${limit}자 이하로 작성해주세요.`;
    }
    return true;
  })
  
  defineRule('minMax', (value, [min, max]) => {
    if (!value || !value.length) {
      return true;
    }
    // Check if email
    if (value.length > max) {
      return `${max}자 이하로 작성해주세요..`;
    }
  
    if (value.length < min) {
      return `${min}자 이상으로 작성해주세요.`;
    }
  
    return true;
  })

  defineRule('passwordLength', (value) => {
       // Field is empty, should pass
       if (!value || !value.length) {
        return true;
      }
      // Check if email
      if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g.test(value)) {
        return '비밀번호 형식이 올바르지 않습니다.';
      }
      return true;
      
  })
})

