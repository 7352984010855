import { Method } from "~/enums";
import moment from 'moment';


export const useUserStore = defineStore("userStore", {
  state: () => ({
   userInfo: useSessionStorage('userInfo', {} as any),
   userSeq: useSessionStorage('userSeq', 0 as number),
   userLoginInfo: useSessionStorage('userLoginInfo', {} as any),
   accessToken: useSessionStorage('accessToken', '' as string),
   refreshToken: useSessionStorage('refreshToken', '' as string),
  }),
  getters: {},
  actions: {
    async login(userId: string, password: string){
      
      try{
        const res = await useFetch('/api/v1/signin', {method: Method.POST, query:{userId: userId, password: password}}) as any;

        if(res.data.value === null && res.error.value !== null){
          throw res.error.value;
        }

        this.userInfo = res.data.value;

        let options = {
          expires: moment().add(res.data.value.refreshTokenExpiresIn, 'second').toDate(),
        }

        let cookies = useCookie('userToken', options);
        cookies.value = res.data.value.refreshToken;

        this.userSeq = res.data.value.userSeq;
        this.accessToken = res.data.value.accessToken;
        this.refreshToken = res.data.value.refreshToken;

        this.userLoginInfo = await this.getUserInfo(this.userSeq);
        return res;
      } catch(e){

        throw e;
      }
 
    },
    async getUserInfo(userSeq: number){
      console.log(userSeq);
      try{
        const res = await useCustomFetch(`/api/v1/users/${userSeq}`, {method: Method.GET});

        if(res?.data.value){
          return res.data.value;
        }
      } catch(e){
        console.log(e)
        throw e;
      }
    },
    async logout(){
      try{
        this.userInfo = {};
        this.userSeq = 0;
        this.userLoginInfo = {};
        this.accessToken = '';
        this.refreshToken = '';
      }catch(e){
        throw e
      }
    },
    async getAdminUser(
      pageNo: number,
      pageSize: number,
      startDate?: string,
      endDate?: string,
      useYn?: string,
      searchKind?: 'userName' | 'userId',
      searchText?: string
    ){
      const res = await useCustomFetch(`/api/v1/users`, {method: Method.GET, query: {
        pageNo: pageNo,
        pageSize: pageSize,
        startDate: startDate,
        endDate: endDate,
        useYn: useYn,
        searchKind: searchKind,
        searchText: searchText,
      }});

      if(res?.data.value){
        return res.data.value;
      } else {
        throw res?.error.value;
      }
    },
    async addAdminUser(
      authGroupSeq: number,
      displayName: string,
      accountStatus: string,
      mainDomain: string,
      subDomain: string,
      userDeptName: string,
      userEmail: string,
      userId: string,
      userName: string,
      userPassword: string
    ){
      let object = {
        authGroupSeq: authGroupSeq,
        displayName: displayName,
        accountStatus: accountStatus,
        mainDomain: mainDomain,
        subDomain: subDomain,
        userDeptName: userDeptName,
        userEmail: userEmail,
        userId: userId,
        userName: userName,
        userPassword: userPassword
      }

      let form = new FormData();

      form.append('contents', JSON.stringify(object));

      const res = await useCustomFetch(`/api/v1/users`, {method: Method.POST, body: form });

      if(res?.data.value){
        return res.data.value;
      } else {
        throw(res?.error.value);
      }
    },
    async modifyAdminUser(
      updateKind: string,
      userSeq: string,
      accountStatus?: string | null,
      authGroupSeq?: string | null,
      displayName?: string | null,
      mainDomain?: string | null,
      subDomain?: string | null,
      userDeptName?: string | null,
      userName?: string | null,
      userEmail?: string | null,
      userPassword?: string | null
    ){
      if(!userDeptName) userDeptName = null;
      if(!userName) userName = null;
      if(!userEmail) userEmail = null;
      if(!userPassword) userPassword = null;



      let object;

      if(updateKind === 'private'){
        object = {
          userDeptName: userDeptName,
          userName: userName,
          userEmail: userEmail,
          userPassword: userPassword
        }
      } else if(updateKind === 'admin'){
        object = {
          accountStatus: accountStatus,
          authGroupSeq: authGroupSeq,
          displayName: displayName,
          mainDomain: mainDomain,
          subDomain: subDomain,
          userDeptName: userDeptName,
          userName: userName,
          userEmail: userEmail,
          userPassword: userPassword
        }
      }

      console.log(object);

      let form = new FormData();

      form.append('contents', JSON.stringify(object));

      const res = await useCustomFetch(`/api/v1/users/${userSeq}`, {method: Method.PUT, query: {updateKind: updateKind}, body: form});

      if(res?.data.value){
        return res.data.value;
      } else {
        throw res?.error.value;
      }

    },
    async alreadyUseId(id: string){
      const res = await useCustomFetch(`/api/v1/users/checkId`, {method: Method.GET, query:{userId: id}});

      console.log(res);

      return res;
    },
    async removeAdmin(idList: number[]){
      const res = await useCustomFetch(`/api/v1/users`, {method: Method.DELETE, query: {userSeq: idList}});

      return res;
    },
    async validPassCheck(userSeq: number, originPass: string){
      const res = await useCustomFetch(`/api/v1/users/${userSeq}/validate-pass`, {method: Method.GET, query: {userPassword: originPass}});

      return res;
    }
    // async addUserInfo(
    //   posting: number,
    //   team: string,
    //   name: string
    // )
  }
})
