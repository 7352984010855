

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, context) => {
    let router = useRouter();
    // console.log(error, context);

    // if(error.statusCode !== 404){
    //   router.push('/mobile/error-page')
    // }
   
  }
})