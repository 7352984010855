export enum Method {
	GET = "GET",
	POST = "POST",
	PATCH = "PATCH",
	PUT = "PUT",
	DELETE = "DELETE",
	HEAD = "HEAD",
	OPTIONS = "OPTIONS",
	CONNECT = "CONNECT",
	TRACE = "TRACE",
}

export enum UserAuthGroup {
	Admin = 1, 				 				//관리자
	Writer = 2 				 				//작성자
}

export enum UserAuthGroupStr {
	Admin = "admin", 				 				//관리자
	Writer = "writer" 				 				//작성자
}

/** 토큰 용도 타입 */
export enum TokenType {
	Access,											//액세스 토큰
	Refresh,										//리프레쉬 토큰
	ChangePass										//최초 로그인 비밀번호 번경용 토큰
}

export enum CustomHeaderKeys {
	UserHeader = "user-header",
}

export enum LoginKind {
	Login = "login",
	Logout = "logout"
}

export enum LoginResult {
	Fail = "fail",
	Success = "success"
}

export enum UseYn {
	Y = "Y",
	N = "N"
}

export enum TokenType {
	BEARER = "Bearer"
}

export enum DateUnit {
	Day = "days",
	Month = "months",
	Year = "years",
	Hour = "hours",
	Minute = "minutes",
	Second = "seconds",
	Date = "date"
}

export enum DateFormatKind {
	YYYY = "YYYY",
	MM = "MM",
	YYYY_MM = "YYYY-MM",
	YYYY_MM_DD = "YYYY-MM-DD",
	YYYY_MM_DD_HH = "YYYY-MM-DD HH",
	YYYY_MM_DD_HH_mm = "YYYY-MM-DD HH:mm",
	YYYY_MM_DD_HH_mm_ss = "YYYY-MM-DD HH:mm:ss",
	YYYYMMDDHHmmss = "YYYYMMDDHHmmss",
	YYYY_MM_DD_00_00_00 = "YYYY-MM-DD 00:00:00",
	YYYY_MM_DD_23_59_59 = "YYYY-MM-DD 23:59:59"
}

export enum IsYnNum {
	No = "0",
	Yes = "1"
}

export enum SEP {
	SEMI_COLONE = ";",
	COMMA = ","
}

/** 공통 검색어 입력 타입  */
export enum SearchKind {
	total = "total",
	title = "title",
	content = "content",
	email = "email",
	recipientName = "recipientName",
	userName = "userName",
	userId = "userId"
}

export enum Result {
	success = "success",
	fail = "fail"
}

export enum PeriodKind {
	fromto = "fromto",
	unlimit = "unlimit"
}

export enum QnaKind {
	mainDomain = "mainDomain",
	subDomain = "subDomain"
}

export enum RecipientKind {
	homepage = "homepage",
	directly = "directly"
}

export enum UserUpdateKind {
	admin = "admin",
	private = "private"
}