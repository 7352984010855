import type { RouterConfig } from "nuxt/schema";
import {createWebHistory} from 'vue-router';
import { useUserStore } from "~/store/userStore";

/** Router 규격
 *  name: Router 명, required
 *  path: web 주소
 *  meta: 페이지 속성
 *      - layout : 레이아웃 명(레이아웃 파일명)
 *      - scriptName: 스크립트 파일명 배열(string[])
 *  component: 해당 파일 경로
 *  children: 하위 router 정의
 */
export default <RouterConfig>{
  mode:'history',
  history: createWebHistory,
  routes:(_routes: any) => {
    const baseRoute =   [
      {name: 'page', path: '/', redirect:{path: '/index'},meta:{layout:'homepage'}, component: () => import('@/client/pages/index.vue'), children:[
        {name: 'page-popup-window-id', path:'popup/window/:id',meta:{layout:'empty-layout'}, component: () => import('@/client/pages/popup_modal.vue')},
        {name: 'page-admin-login', path:'/admin-login',meta:{layout:'empty-layout'}, component: () => import('@/client/pages/admin_login.vue')},
        {name: 'page-index', path: 'index', meta:{scriptName: ['main.js'], title: '한국녹색기후기술원',}, component: () => import('@/client/pages/main.vue')},
        {name: 'page-add_write', path:'add-write', meta:{scriptName: ['sub.js']}, component: () => import("@/client/pages/add_write.vue")},
        // {name: 'page-popup-add_write', path:'popup-add-write', meta:{scriptName: ['sub.js'], boardSettingSeq:2}, component: () => import("@/client/pages/add_write.vue")},
        {name: 'page-certification', path: 'certification', meta:{scriptName: ['sub.js']}, children: [
          {name: 'page-certification-01', path: 'certification01', meta: {title: 'KGCT :: 건축물에너지효율등급 인증'}, component: () => import('@/client/pages/certification/certification01.vue')},
          {name: 'page-certification-02', path: 'certification02', meta: {title: 'KGCT :: 제로에너지건축물 인증'}, component: () => import('@/client/pages/certification/certification02.vue')},
          {name: 'page-certification-03', path: 'certification03', meta: {title: 'KGCT :: 에너지절약형 친환경주택'}, component: () => import('@/client/pages/certification/certification03.vue')},
          {name: 'page-certification-04', path: 'certification04', meta: {title: 'KGCT :: 장애물 없는 생활환경 인증'}, component: () => import('@/client/pages/certification/certification04.vue')},
          {name: 'page-certification-05', path: 'certification05', meta: {title: 'KGCT :: 교육시설안전 인증'}, component: () => import('@/client/pages/certification/certification05.vue')},
          {name: 'page-certification-06', path: 'certification06', meta: {title: 'KGCT :: KOLAS 국제공인시험기관'}, component: () => import('@/client/pages/certification/certification06.vue')}
        ]},
        {name: 'page-company', path: 'company', meta:{scriptName: ['sub.js']}, children: [
          {name: 'page-company-about', path: 'about', meta: {title: 'KGCT :: 기술원 비전·가치'}, component: () => import('@/client/pages/company/about.vue')},
          {name: 'page-company-ci', path: 'ci', meta: {title: 'KGCT :: CI'}, component: () => import('@/client/pages/company/ci.vue')},
          {name: 'page-company-group', path: 'group', meta: {title: 'KGCT :: 조직도·연락처'}, component: () => import('@/client/pages/company/group.vue')},
          {name: 'page-company-history', path: 'history', meta: {title: 'KGCT :: 기관연혁'}, component: () => import('@/client/pages/company/history.vue')},
          {name: 'page-company-location', path: 'location', meta: {title: 'KGCT :: 오시는 길'}, component: () => import('@/client/pages/company/location.vue')}
        ]},
        {name: 'page-diagnosis', path: 'diagnosis', meta:{scriptName: ['sub.js']}, children: [
          {name: 'page-diagnosis-01', path: 'diagnosis01', meta: {title: 'KGCT :: 건축물 안전진단 및 점검'}, component: () => import('@/client/pages/diagnosis/diagnosis01.vue')},
          {name: 'page-diagnosis-02', path: 'diagnosis02', meta: {title: 'KGCT :: 건축물 관리점검'}, component: () => import('@/client/pages/diagnosis/diagnosis02.vue')},
          {name: 'page-diagnosis-03', path: 'diagnosis03', meta: {title: 'KGCT :: 에너지진단'}, component: () => import('@/client/pages/diagnosis/diagnosis03.vue')},
          {name: 'page-diagnosis-04', path: 'diagnosis04', meta: {title: 'KGCT :: 기계설비 성능점검'}, component: () => import('@/client/pages/diagnosis/diagnosis04.vue')},
          {name: 'page-diagnosis-05', path: 'diagnosis05', meta: {title: 'KGCT :: 열수송관 안전진단'}, component: () => import('@/client/pages/diagnosis/diagnosis05.vue')},
          {name: 'page-diagnosis-06', path: 'diagnosis06', meta: {title: 'KGCT :: 성능개선'}, component: () => import('@/client/pages/diagnosis/diagnosis06.vue')}
        ]},
        {name: 'page-media', path: 'media', meta: {scriptName: ['sub.js'], name:"미디어센터"}, children: [
          {name: 'page-media-01', path: 'media01', meta: {title: '공지사항', boardSettingSeq: 1}, component: () => import('@/client/pages/media/media01.vue'), children: [
            {name: 'page-media-01-view-id', path: 'view/:id', component: () => import('@/client/pages/media/[id].vue')},
            {name: 'page-media-01-add_write', path:'add-write', meta:{scriptName: ['sub.js']}, component: () => import("@/client/pages/add_write.vue")},
          ]},
          {name: 'page-media-02', path: 'media02', meta: {title: '분야별 Q&A', boardSettingSeq: 3}, component: () => import('@/client/pages/media/media02.vue'), children: [
            {name: 'page-media-02-view-qnaId', path: 'view/:qnaId', component: () => import('@/client/pages/media/[qnaId].vue')},
            {name: 'page-media-02-qna_write', path:'qna-write', meta:{scriptName: ['sub.js']}, component: () => import("@/client/pages/qna_write.vue")},
          ]},
          {name: 'page-media-03', path: 'media03', meta: {title: '뉴스레터', boardSettingSeq: 4}, component: () => import('@/client/pages/media/media03.vue'),children: [
            {name: 'page-media-03-view-id', path: 'view/:id', component: () => import('@/client/pages/media/[id].vue')},
            {name: 'page-media-03-add_write', path: 'add-write', meta:{scriptName: ['sub.js']}, component: () => import("@/client/pages/add_write.vue")}
          ]},
          {name: 'page-media-04', path: 'media04', meta: {title: '채용정보'}, component: () => import('@/client/pages/media/media04.vue'),children: [
          ]},
          // {name: 'page-media-qna_view', path: 'media01', meta: {title: 'KGCT :: 미디어센터'}, component: () => import('@/client/pages/media/media_qna_view.vue')},
          // {name: 'page-media-qna_inquiry', path: 'media01', meta: {title: 'KGCT :: 미디어센터'}, component: () => import('@/client/pages/media/media_qna_inquiry.vue')},
        ]},
        {name: 'page-performance', path: 'performance', meta: {scriptName: ['sub.js'], name: '주요실적'}, children: [
          {name: 'page-performance-01', path: 'performance01', meta: {title: 'KGCT :: 주요실적 - 인증·평가', boardSettingSeq: 5}, component: () => import('@/client/pages/performance/performance01_list.vue'), children: [
            {name: 'page-performance-01-view-id', path: 'view/:id', component: () => import('@/client/pages/performance/[id].vue')},
            {name: 'page-performance-01-add_write', path: 'add-write', meta: {scriptName: ['sub.js']}, component: () => import('@/client/pages/add_write.vue')}
          ]},
          {name: 'page-performance-02', path: 'performance02', meta: {title: 'KGCT :: 주요실적 - 엔지니어링', boardSettingSeq: 6}, component: () => import('@/client/pages/performance/performance02_list.vue'), children: [
            {name: 'page-performance-02-view-id', path: 'view/:id', component: () => import('@/client/pages/performance/[id].vue')},
            {name: 'page-performance-02-add_write', path: 'add-write', meta: {scriptName: ['sub.js']}, component: () => import('@/client/pages/add_write.vue')}
          ]},
          {name: 'page-performance-03', path: 'performance03', meta: {title: 'KGCT :: 주요실적 - R&D', boardSettingSeq: 7}, component: () => import('@/client/pages/performance/performance03_list.vue'), children: [
            {name: 'page-performance-03-view-id', path: 'view/:id', component: () => import('@/client/pages/performance/[id].vue')},
            {name: 'page-performance-03-add_write', path: 'add-write', meta: {scriptName: ['sub.js']}, component: () => import('@/client/pages/add_write.vue')}
          ]},
        ]},
        {name: 'page-policy', path: 'policy',meta:{name: '개인정보처리 방침'}, children: [
          {name: 'page-policy-01', path: 'policy01', meta: {title: 'KGCT :: 개인정보처리 방침'}, component: () => import('@/client/pages/policy/policy01.vue')},
          {name: 'page-policy-02', path: 'policy02', meta: {title: 'KGCT :: 개인정보처리 방침'}, component: () => import('@/client/pages/policy/policy02.vue')},
          {name: 'page-policy-03', path: 'policy03', meta: {title: 'KGCT :: 개인정보처리 방침'}, component: () => import('@/client/pages/policy/policy03.vue')}
        ]},
        {name: 'page-rnd', path: 'rnd', meta: {scriptName: ['sub.js'], name: "연구·개발"}, children: [
          {name: 'page-rnd-01', path: 'rnd01', meta: {title: 'KGCT :: R&D'}, component: () => import('@/client/pages/rnd/rnd01.vue')},
          {name: 'page-rnd-02', path: 'rnd02', meta: {title: 'KGCT :: 플랫폼'}, component: () => import('@/client/pages/rnd/rnd02.vue')},
          // {name: 'page-rnd-03', path: 'rnd03', meta: {title: 'KGCT :: 연구·개발 주요성과'}, component: () => import('@/client/pages/rnd/rnd03.vue')}
        ]},
        {name: 'page-popup', path: 'popup', meta: {scriptName: ['sub.js'], name: '팝업'}, children: [
          {name: 'page-popup-base', path: 'base', meta: {title: '팝업'}, component: () => import('@/client/pages/popup/index.vue'), children: [
            {name: 'page-popup-base-id', path: ':id', component: () => import('@/client/pages/popup/[id].vue')},
            {name: 'page-popup-base-list', path: 'list', meta:{layout:'empty-layout'}, component: () => import('@/client/pages/popup/list.vue')},
            {name: 'page-popup-base-add_write', path: 'add-write', component: () => import('@/client/pages/add_write.vue')}
          ]},
        ]}
      ]},
       /** SWAGGER */
       {name: 'swagger', path: '/swagger-ui', component: () => import("@/client/swagger-ui.vue")},
       {name: 'admin-login',  path: '/admin/login', component: () => import("@/client/pages/admin_login.vue")},

      //  admin page
      {name: 'admin' , path: '/admin', component: () => import("@/client/pages/admin/index.vue"), beforeEnter: (to, from, next) => {
        const userStore = useUserStore();
        if(!userStore.accessToken) {
          next('/admin/login')
        } else {
          next();
        }
      }, children: [
        {name: 'admin-content', path: 'content', component: () => import("@/client/pages/admin/content/index.vue"), children: [
          {name: 'admin-content-notice', path: 'notice', component: () => import("@/client/pages/admin/content/notice.vue")},
          {name: 'admin-content-qna', path: 'qna', component: () => import("@/client/pages/admin/content/qna.vue")},
          {name: 'admin-content-certification', path: 'certification', component: () => import("@/client/pages/admin/content/certification.vue")},
          {name: 'admin-content-engineering', path: 'engineering', component: () => import("@/client/pages/admin/content/engineering.vue")},
          {name: 'admin-content-rnd', path: 'rnd', component: () => import("@/client/pages/admin/content/rnd.vue")},
          {name: 'admin-content-banner', path: 'banner', component: () => import("@/client/pages/admin/content/banner.vue")},
          {name: 'admin-content-popup', path: 'popup', component: () => import("@/client/pages/admin/content/popup.vue")},
          {name: 'admin-content-newsletter', path: 'newsletter', component: () => import("@/client/pages/admin/content/newsletter.vue")},
          {name: 'admin-content-subscribe', path: 'subscribe', component: () => import("@/client/pages/admin/content/subscribe.vue")},
          {name: 'admin-content-user', path: 'user', component: () => import("@/client/pages/admin/content/user.vue")},
          {name: 'admin-content-id', path: ':id', component:() => import("@/client/pages/admin/content/[id].vue")},
          {name: 'admin-content-qna-qnaid', path: 'qna/:qnaid', component:() => import("@/client/pages/admin/content/[qnaid].vue")},
          {name: 'admin-content-popup-modify', path: 'popup-modify', component: () => import("@/client/pages/admin/content/popup-modify.vue")}
        ]},       
        {name: 'admin-content-modify', path: 'content-modify', component: () => import('@/client/pages/admin/content-modify.vue')},
        {name: 'admin-qna-content-modify', path: 'qna-content-modify', component: () => import("@/client/pages/admin/qna-content-modify.vue")},

      ]},
      {path: '/:pathMatch(.*)*', redirect: '/not-found'},
      {name: 'not-found', path:"/not-found", component: () => import('@/client/pages/not_found.vue')}
    ]

    console.log(baseRoute);

    return baseRoute;
  }
}