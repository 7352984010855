import plugin_vue3_yxgfhZuyXj from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.9.0_vue@3.4.27/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import unhead_jGzvgmX4F7 from "/app/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.19.3_rollup@4.9.0_sass@1.69.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import vueuse_head_polyfill_Tdjdtpu5Iq from "/app/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.19.3_rollup@4.9.0_sass@1.69.5/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import router_1RtbERPIAW from "/app/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.19.3_rollup@4.9.0_sass@1.69.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_7niuKKqbrP from "/app/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.19.3_rollup@4.9.0_sass@1.69.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LMGGYuy6mv from "/app/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.4_rollup@4.9.0_vue@3.4.27/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import i18n_t1fWiBNUkj from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.9.0_vue@3.4.27/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_gWl0Ed24Do from "/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.9.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_A4J26Ulybk from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.5.0_next-auth@4.21.1_rollup@4.9.0/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import debug_Lo3a8j7lwx from "/app/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.19.3_rollup@4.9.0_sass@1.69.5/node_modules/nuxt/dist/app/plugins/debug.js";
import chunk_reload_client_DwqetwSQTp from "/app/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.19.3_rollup@4.9.0_sass@1.69.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import quill_client_tidWnrXsFD from "/app/src/client/plugins/quill.client.ts";
import vee_validate_sykkdhZOFm from "/app/src/client/plugins/vee-validate.ts";
import plugin_rfh4gT0kOd from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.1_pinia@2.1.7_rollup@4.9.0/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import error_handle_Lhl0DV9Yxv from "/app/src/client/plugins/error-handle.ts";
import i18n_2y6Rk9zdYC from "/app/src/client/plugins/i18n.ts";
export default [
  plugin_vue3_yxgfhZuyXj,
  components_plugin_KR1HBZs4kY,
  unhead_jGzvgmX4F7,
  vueuse_head_polyfill_Tdjdtpu5Iq,
  router_1RtbERPIAW,
  prefetch_client_7niuKKqbrP,
  plugin_client_LMGGYuy6mv,
  i18n_t1fWiBNUkj,
  plugin_gWl0Ed24Do,
  plugin_A4J26Ulybk,
  debug_Lo3a8j7lwx,
  chunk_reload_client_DwqetwSQTp,
  quill_client_tidWnrXsFD,
  vee_validate_sykkdhZOFm,
  plugin_rfh4gT0kOd,
  error_handle_Lhl0DV9Yxv,
  i18n_2y6Rk9zdYC
]